import React from 'react';
import styled from 'styled-components';
import styles from './Post.module.css';

const Main = styled.main`
  font-family: Lato, sans-serif;
  display: grid;
  grid-template-columns: 4rem 3fr 1fr 2rem;
  margin-top: 2rem;
  grid-column-gap: 2rem;
`;

const Header = styled.header`
  grid-column: 2 / 5;
`;

const Content = styled.section`
  grid-column: 2 / 3;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.4;
`;

const Footer = styled.footer`
  grid-column: 1 / 5;
  background-color: #37474f;
  padding: 0.7rem 1rem;
  margin-top: 3rem;
  text-align: right;
`;

const Post = () => {
  return (
    <Main>
      <Header>
        <p className={styles.subheading}>Subheading for the Page</p>
        <h1 className={styles.heading}>Main Header of the Page</h1>
      </Header>

      <Content className={styles.content}>
        <img
          src='https://images.unsplash.com/photo-1466436578965-5cba086a1824?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=ac7f8b732c22f512fd982ffddc2078d6'
          alt='large'
          className={styles['poster-image']}
        />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore cum aperiam ex,
          recusandae non qui tempore. Quisquam, cupiditate! In suscipit tenetur sit beatae
          inventore aliquid fugit expedita quis totam. Pariatur?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore cum aperiam ex,
          recusandae non qui tempore. Quisquam, cupiditate! In suscipit tenetur sit beatae
          inventore aliquid fugit expedita quis totam. Pariatur? Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Dolore cum aperiam ex, recusandae non qui
          tempore. Quisquam, cupiditate! In suscipit tenetur sit beatae inventore aliquid
          fugit expedita quis totam. Pariatur? Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Dolore cum aperiam ex, recusandae non qui tempore. Quisquam,
          cupiditate! In suscipit tenetur sit beatae inventore aliquid fugit expedita quis
          totam. Pariatur?
        </p>
        <p>
          Velit scelerisque in dictum non consectetur. Potenti nullam ac tortor vitae
          purus faucibus ornare suspendisse sed. Interdum velit euismod in pellentesque
          massa. Amet porttitor eget dolor morbi non. Pellentesque diam volutpat commodo
          sed egestas egestas. Massa sapien faucibus et molestie ac. Rhoncus aenean vel
          elit scelerisque mauris. In est ante in nibh mauris cursus mattis molestie a. At
          imperdiet dui accumsan sit amet nulla. Egestas erat imperdiet sed euismod nisi.
          In pellentesque massa placerat duis ultricies lacus sed turpis tincidunt.
          Maecenas volutpat blandit aliquam etiam. Orci phasellus egestas tellus rutrum
          tellus pellentesque. Id ornare arcu odio ut sem nulla pharetra diam sit.
          Faucibus pulvinar elementum integer enim. At risus viverra adipiscing at in
          tellus. Eget mauris pharetra et ultrices. Nulla posuere sollicitudin aliquam
          ultrices sagittis orci a. Diam quam nulla porttitor massa id neque aliquam.
          Pharetra convallis posuere morbi leo urna molestie. Venenatis cras sed felis
          eget velit aliquet. In nulla posuere sollicitudin aliquam ultrices sagittis.
          Eget nulla facilisi etiam dignissim diam. Commodo sed egestas egestas fringilla
          phasellus. Etiam erat velit scelerisque in dictum non consectetur a erat.
          Pretium aenean pharetra magna ac. Mattis nunc sed blandit libero. Quam id leo in
          vitae turpis massa sed elementum. Sem viverra aliquet eget sit amet. Neque
          aliquam vestibulum morbi blandit cursus risus at ultrices mi. Felis donec et
          odio pellentesque diam. Convallis posuere morbi leo urna molestie at elementum
          eu. Tincidunt ornare massa eget egestas purus viverra. Id faucibus nisl
          tincidunt eget. Quisque id diam vel quam elementum pulvinar. Aliquam purus sit
          amet luctus venenatis lectus magna. Diam donec adipiscing tristique risus.
        </p>
      </Content>

      <aside className={styles.aside}>
        <h4 className={styles.heading}>Other Articles you might Enjoy</h4>
        <div className={styles.card}>
          <img
            src='https://images.unsplash.com/photo-1457269315919-3cfc794943cd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=2c42c1cac3092204f4c1afdca4d44e99'
            alt=''
          />
          <div>
            <p className={`${styles.heading} ${styles.title}`}>The big subtext</p>
            <p className={styles.author}>Mathews</p>
          </div>
        </div>

        <div className={styles.card}>
          <img
            src='https://images.unsplash.com/photo-1528640936814-4460bc015292?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=66812b5fda04c80ff762c8a920f562f3'
            alt=''
          />
          <div>
            <p className={`${styles.heading} ${styles.title}`}>The bug subtext</p>
            <p className={styles.author}>Harsha</p>
          </div>
        </div>
      </aside>

      <Footer>
        <a
          href='https://twitter.com/'
          target='_blank'
          rel='noopener noreferrer'
          className={styles['name-link']}
        >
          By Author
        </a>
      </Footer>
    </Main>
  );
};

export const PostMemo = React.memo(Post);
